/* eslint-disable vue/require-prop-types */
import type { App } from 'vue';
import { computed, defineAsyncComponent, defineComponent, h, ref, shallowRef, watch } from 'vue';
import { useAxiosForm } from '@aspect/shared/composables/use-axios-form.ts';

const resolveCallback = ref<CallableFunction>();

const resolver = {
    setResolveCallback: (callback: CallableFunction) => {
        resolveCallback.value = callback;
    },
    resolve: (name: string) => resolveCallback.value!(name),
};

export interface InlineModalPluginOptions {
    resolve: (name: string) => any
}

export interface InlineModal {
    baseURL: string;
    component: string;
    key: string;
    nonce: string;
    props: any[];
    redirectURL: string;
}

export const InlineModalPlugin = {
    install(app: App, options: InlineModalPluginOptions) {
        resolver.setResolveCallback(options.resolve);
    },
};

export const AspectModalInline = defineComponent(
    (props: {
        href: string;
        open: boolean;
        loading?: boolean;
        data?: Record<string, any>;
        payload?: Record<string, any>;
        method?: 'get' | 'post';
    }, { emit }) => {
        const request = useAxiosForm();
        const component = shallowRef();
        const modal = ref<InlineModal | null>(null);
        const loadingDelay = 150;
        const isLoading = ref(false);

        async function fetch() {
            if (props.method === 'post') {
                return await request.post(props.href, {
                    only: ['modal'],
                    ...(props.payload || {}),
                });
            }

            return await request.get(props.href, {
                only: ['modal'],
            });
        }

        watch(() => props.open, async () => {
            if (!props.open) {
                return;
            }

            isLoading.value = true;

            setTimeout(() => {
                if (isLoading.value) {
                    emit('update:loading', true);
                }
            }, loadingDelay);

            const response = await fetch();

            modal.value = response?.data?.props?.modal;
            component.value = defineAsyncComponent({
                loader: () => resolver.resolve((modal.value as InlineModal).component),
            });
        });

        const vnode = computed(() => {
            return !component.value ? '' : h(component.value, {
                ...(props.data || {}),
                ...(modal.value as InlineModal).props,
                'key': (modal.value as InlineModal).key,
                'inline': true,
                'open': props.open,
                'onUpdate:open': (value: boolean) => emit('update:open', value),
                'onClosed': () => {
                    component.value = null;
                },
                'onSuccess': (data: any) => {
                    emit('update:open', false);
                    emit('success', data);
                },
                'onMounted': () => {
                    isLoading.value = false;
                    emit('update:loading', false);
                },
                'onReload': async () => {
                    const response = await fetch();
                    modal.value = response?.data?.props?.modal;
                    emit('reload');
                }
            } as any);
        });

        return () => vnode.value;
    },
    {
        props: ['href', 'open', 'loading', 'data', 'payload', 'method'],
        emits: ['update:open', 'update:loading', 'success', 'reload'],
    },
);
